import { useState, useEffect, useCallback, useRef } from "react";
import { Capacitor } from "@capacitor/core";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Button,
	Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// cmp
import DrawerDialog from "../DrawerDialog";
import CustomSwitch from "../custom-switch";
import DimSlider from "../dim-slider";
import ListItemContainer from "../ListItemContainer";
import StreamingPlayer from "../streaming-player/StreamingPlayer";
import Svg from "../svg";
import Toast from "../Toast";
// plugins
import { TuyaBridge } from "../../plugins/TuyaBridge";
import { LibVlc } from "../../plugins/LibVlc";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
import useIsSmallScreen from "../../hooks/useIsSmallScreen";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import Glient from "../../services/glient";
import Devices from "../../services/devices";
import DeviceType from "../../services/device-type";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { EpDevice } from "../../types/device";
import type { AttributeId } from "../../types/cluster";
import type { HandlerId, CmdSendGeneralCmdWrite, CmdSendActionCmd } from "../../types/message";
import type { DataType, DataTypeMapper, RelativePathPrefix, CmdId } from "../../types/misc";

type Props = {
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"C38A">;
	relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
};

type RouterState = {
	openRingDialog: boolean;
};

const STREAMING_COUNTDOWN_FALLBACK = 60;

const getClientStreamingCapabilities = () => {
	let supportMask = Constants.StreamingCapabilities.HLS | Constants.StreamingCapabilities.MJPEG; //0;
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (window.RTCPeerConnection && navigator.mediaDevices?.getUserMedia) { // check for "navigator.mediaDevices" is necessary as of secure context (don't remove the "?")
		supportMask += Constants.StreamingCapabilities.WebRTC;
	}
	// if (HLS) {
	// 	supportMask += Constants.StreamingCapabilities.HLS;
	// }
	if (Capacitor.isNativePlatform()) {
		supportMask += Constants.StreamingCapabilities.RTSP;
	}
	// if (MJPEG) {
	// 	supportMask += Constants.StreamingCapabilities.MJPEG;
	// }

	return supportMask;
};

const getDeviceStreamingCapabilities = (epDevice: EpDevice, deviceType: DeviceTypeT<"C38A">): number => {
	// prefered order: WebRTC, RTSP, HLS, MJPEG
	const cluster = epDevice.getClusterByCapAndClusterId(deviceType.cap, deviceType.clusterId);
	if (cluster === undefined) {
		return 0;
	}

	const streamingCapabilities = Capacitor.isNativePlatform()
		? ClusterConstants.DC38A.Attributes.MobileStreamingCapabilities
		: ClusterConstants.DC38A.Attributes.WebStreamingCapabilities;
	if (cluster.hasOwnProperty(streamingCapabilities) && typeof cluster[streamingCapabilities] === "number") {
		return cluster[streamingCapabilities];
	}

	// Fallback
	if (epDevice.getAttribute(Constants.Device.Attributes.Property.DeviceType) === Constants.Device.Attributes.Value.DeviceType.Tuya) {
		// tuya cameras are asumed to support HLS or WebRTC (not supported for web yet)
		// TODO: implement WebRTC
		return Constants.StreamingCapabilities.HLS;
	}
	if (Capacitor.isNativePlatform()) {
		// all old cameras are asumed to support RTSP (for mobile)
		return Constants.StreamingCapabilities.RTSP;
	}
	if (cluster[ClusterConstants.DC38A.Attributes.MjpegCapability] ?? true) { // default to true
		return Constants.StreamingCapabilities.MJPEG;
	}

	return 0;
};

const DC38A = (props: Props) => {
	const send = useSend();
	const isSmallScreen = useIsSmallScreen();

	const { t } = useTranslation();
	const { state } = useLocation();

	const locationState = state as RouterState | null;

	const { channelInfo } = useUserData();
	const countdownStartTime = channelInfo?.features.streaming?.maxDuration ?? STREAMING_COUNTDOWN_FALLBACK;

	const streamingCapabilities = getClientStreamingCapabilities() & getDeviceStreamingCapabilities(props.epDevice, props.deviceType);
	const isTuya = props.epDevice.getAttribute(Constants.Device.Attributes.Property.DeviceType) === Constants.Device.Attributes.Value.DeviceType.Tuya;
	const clusterC38A = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);

	const [cameraLightToggle, setCameraLightToggle] = useDynamicUpdateState(clusterC38A?.[ClusterConstants.DC38A.Attributes.FloodlightSwitchTuya] ?? false);
	const [dimLevel, setDimLevel] = useDynamicUpdateState(clusterC38A?.[ClusterConstants.DC38A.Attributes.FloodlightLevelTuya] ?? null);

	const [incomingRingDialogOpen, setIncomingRingDialogOpen] = useState(locationState?.openRingDialog ?? false);
	const [webStreamingDialogOpen, setWebStreamingDialogOpen] = useState(false);
	const [isStreaming, setIsStreaming] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const glientHandlerIdRef = useRef<HandlerId>();
	const removeListenerSetStreamUrlRef = useRef<(() => Promise<void>) | undefined>(undefined);

	useEffect(() => (
		() => {
			if (glientHandlerIdRef.current) {
				Glient.abort(glientHandlerIdRef.current);
			}
			void removeListenerSetStreamUrlRef.current?.();
		}
	), []);

	const sendCmd = useCallback(<DT extends DeviceTypeT<"C38A">, DAT extends DataType>(deviceType: DT, attributeId: AttributeId<DT["cap"], DT["clusterId"]>, datatype: DAT, value: DataTypeMapper[DAT]) => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: deviceType.cap,
			clusterId: deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: attributeId,
				datatype: datatype,
				value: value,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"C38A">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	}, [props.epDevice.gwId, props.epDevice.srcGw, props.epDevice.id, props.epDevice.epId]);

	const handleChangeCameraToggle = useCallback((isInputChecked: boolean) => {
		setCameraLightToggle(isInputChecked);
		sendCmd(DeviceType.DC38A, ClusterConstants.DC38A.Attributes.FloodlightSwitchTuya, Constants.DataType.Boolean, isInputChecked);
	}, [sendCmd]);

	const handleDimLevelChange = useCallback((dimLevel: number) => {
		sendCmd(DeviceType.DC38A, ClusterConstants.DC38A.Attributes.FloodlightLevelTuya, Constants.DataType.UInt8Bit, dimLevel);
	}, [sendCmd]);

	const sendActionCmd = useCallback((cmdId: CmdId, value: number) => {
		const cmd: CmdSendActionCmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: DeviceType.DC38B.cap,
			clusterId: DeviceType.DC38B.clusterId,
			cmdId: cmdId,
		};
		if (typeof value === "number") {
			cmd.value = decimal2Hex(value, 4);
		}
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	}, [props.epDevice.gwId, props.epDevice.srcGw, props.epDevice.id, props.epDevice.epId]);

	const doTuyaStreaming = useCallback(async () => {
		try {
			await TuyaBridge.addListener("cameraPanTilt", ({ direction }) => {
				sendActionCmd(Constants.CameraPanTiltDirections[direction].cmdId, Constants.CameraPanTiltDirections[direction].value);
			});
			await TuyaBridge.liveStreamCamera({ deviceId: props.epDevice.id, deviceTitle: props.epDevice.fullName });
		} catch (error) {
			console.warn("Failed to Load stream URL", error);
			setShowGenericErrorMsg(Date.now());
		} finally {
			await TuyaBridge.removeAllListeners(); // TODO: change to single remove-listener handler
		}
	}, [props.epDevice.id, props.epDevice.fullName, sendActionCmd]);

	const doVlcStreaming = useCallback(async () => {
		const cmdId = Devices.getCmdId(streamingCapabilities);
		if (cmdId === null) {
			console.warn("cmdId is null", streamingCapabilities);
			setShowGenericErrorMsg(Date.now());
			setIsStreaming(false);
		} else {
			const { remove } = await LibVlc.addListener("setStreamUrl", () => {
				glientHandlerIdRef.current = Devices.getStreamUrl(props.epDevice, DeviceType.DC38A, cmdId, async (error, url) => {
					if (!error && url) {
						await LibVlc.setStreamUrl({ url: url });
					} else {
						console.error("get stream url error", error);
						setIsStreaming(false);
						await LibVlc.close();
						setShowGenericErrorMsg(Date.now());
					}
				});
			});
			removeListenerSetStreamUrlRef.current = remove;
			try {
				await LibVlc.stream({
					backText: t("appBarBackText").toUpperCase(),
					deviceTitle: props.epDevice.fullName,
					titleFontSize: isSmallScreen ? 18 : 22,
					navBarSize: isSmallScreen ? 56 : 64,
					useCountdown: true,
					streamDuration: countdownStartTime,
					countdownTextOne: t("media.countdown_one"),
					countdownTextOther: t("media.countdown_other"),
					continueStreamingText: t("media.continue"),
				});
				await remove();
				setIsStreaming(false);
			} catch (error) {
				console.error("catch error vlc", error);
				setIsStreaming(false);
				setShowGenericErrorMsg(Date.now());
			}
		}
	}, [props.epDevice, isSmallScreen, streamingCapabilities, countdownStartTime]);

	const handleWatchStreamClick = useCallback(async () => {
		setIncomingRingDialogOpen(false);

		if (Capacitor.isNativePlatform()) {
			setIsStreaming(true);
			if (isTuya) {
				await doTuyaStreaming();
				setIsStreaming(false);
			} else {
				await doVlcStreaming();
				// setIsStreaming(false); // TODO: done in `doVlcStreaming()` - `Devices.getStreamUrl()` callback
			}
		} else {
			setWebStreamingDialogOpen(true);
		}
	}, [isTuya, doTuyaStreaming, doVlcStreaming]);

	const handleIncomingRingDialogClose = useCallback(() => {
		setIncomingRingDialogOpen(false);
	}, []);

	const handleWebStreamingDialogClose = useCallback(() => {
		setWebStreamingDialogOpen(false);
	}, []);

	const clusterC38C = props.epDevice.getClusterByCapAndClusterId(DeviceType.DC38C.cap, DeviceType.DC38C.clusterId);
	const hasC38AAttributes = Constants.CameraSettingsSupportedAttributes[DeviceType.DC38A.clusterId].some((clusterId) => (clusterC38A?.hasOwnProperty(clusterId)));
	const hasC38CAttributes = Constants.CameraSettingsSupportedAttributes[DeviceType.DC38C.clusterId].some((clusterId) => (clusterC38C?.hasOwnProperty(clusterId)));
	const isRestartSupported = clusterC38A?.[ClusterConstants.DC38A.Attributes.RestartAvailable] ?? true;
	const showAdvancedSettings = hasC38AAttributes || hasC38CAttributes || isRestartSupported;

	return (
		<>
			{props.showFull ?
				<>
					<ListItem>
						<ListItemText primary={t("clusters.DC38A.title")} />
						<ListItemContainer>
							<LoadingButton disabled={webStreamingDialogOpen} loading={isStreaming} onClick={handleWatchStreamClick}>
								{t("clusters.DC38A.buttonText")}
							</LoadingButton>
						</ListItemContainer>
					</ListItem>
					{showAdvancedSettings &&
						<>
							<Divider />
							<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Camera}`}>
								<ListItemText primary={t("clusters.DC38A.advancedSettings")} />
								<ListItemIcon><icons.ChevronRight /></ListItemIcon>
							</ListItemButton>
						</>
					}
					{clusterC38A && (clusterC38A.hasOwnProperty(ClusterConstants.DC38A.Attributes.FloodlightSwitchTuya) && clusterC38A.hasOwnProperty(ClusterConstants.DC38A.Attributes.FloodlightLevelTuya)) &&
						<>
							<Divider />
							<ListItem>
								<ListItemText primary={t("clusters.DC38A.category.cameraLight.title")} />
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText primary={t(cameraLightToggle ? "clusters.DC38A.category.cameraLight.on" : "clusters.DC38A.category.cameraLight.off")} />
								<ListItemContainer><CustomSwitch checked={cameraLightToggle} onChange={handleChangeCameraToggle} /></ListItemContainer>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText primary={t("clusters.DC38A.category.cameraLight.dimLevel")} secondary={`${dimLevel as number}%`} />
								<ListItemContainer style={{ width: "calc(50% - 10px)", marginRight: "10px" }}>
									<DimSlider
										value={dimLevel}
										onChange={setDimLevel}
										onChangeCommitted={handleDimLevelChange}
									/>
								</ListItemContainer>
							</ListItem>
						</>
					}
					{(Capacitor.isNativePlatform() && isTuya) &&
						<>
							<Divider />
							<ListItemButton component={Link} to={`${props.relativePathPrefix}playback`}>
								<ListItemText primary={t("clusters.DC38A.category.playback.title")} />
								<ListItemIcon><icons.ChevronRight /></ListItemIcon>
							</ListItemButton>
							<Divider />
							<ListItemButton component={Link} to={`${props.relativePathPrefix}album`}>
								<ListItemText primary={t("clusters.DC38A.category.album.title")} />
								<ListItemIcon><icons.ChevronRight /></ListItemIcon>
							</ListItemButton>
						</>
					}
				</>
				:
				<LoadingButton
					className="button-C38A-watch"
					disabled={webStreamingDialogOpen}
					loading={isStreaming}
					onClick={handleWatchStreamClick}
				>
					{t("clusters.DC38A.buttonText")}
				</LoadingButton>
			}
			<DrawerDialog
				id="dlg-doorbell-incoming-ring"
				title={props.epDevice.name}
				open={incomingRingDialogOpen}
				onClose={handleIncomingRingDialogClose}
				drawerActions={null}
				dialogActions={<Button className="btn-dlg-action-close" color="inherit" onClick={handleIncomingRingDialogClose}>{t("dialog.close")}</Button>}
			>
				<Typography sx={{ padding: "16px", textAlign: "center" }}>{t("clusters.DFF01.doorbellIncomingRing")}</Typography>
				<div style={{ display: "flex", justifyContent: "space-evenly", padding: "16px 0px 32px" }}>
					<LoadingButton
						variant="contained"
						color="success"
						size="large"
						loading={isStreaming}
						onClick={handleWatchStreamClick}
						sx={{ padding: "16px", borderRadius: "50%" }}
					>
						<Svg src="device-details/mic.svg" size={32} />
					</LoadingButton>
					<Button
						variant="contained"
						color="error"
						size="large"
						onClick={handleIncomingRingDialogClose}
						sx={{ padding: "16px", borderRadius: "50%" }}
					>
						<Svg src="device-details/clear.svg" size={32} />
					</Button>
				</div>
			</DrawerDialog>
			{!Capacitor.isNativePlatform() &&
				<DrawerDialog
					id="dlg-camera-stream"
					title={t("clusters.DC38A.video")}
					initContentOnlyIfOpen={true}
					fullSizeDrawer={true}
					open={webStreamingDialogOpen}
					onClose={handleWebStreamingDialogClose}
					drawerActions={
						<Button
							className="btn-drawer-action-ok"
							variant="contained"
							onClick={handleWebStreamingDialogClose}
							sx={{ minWidth: "40%", maxWidth: "320px" }}
						>
							{t("dialog.ok")}
						</Button>
					}
					dialogActions={
						<Button
							className="btn-dlg-action-ok"
							variant="contained"
							disableElevation={true}
							onClick={handleWebStreamingDialogClose}
						>
							{t("dialog.ok")}
						</Button>
					}
				>
					{(streamingCapabilities === 0) ? t("clusters.DC38A.streamingNotSupported") :
						<StreamingPlayer
							epDevice={props.epDevice}
							streamingCapabilities={streamingCapabilities}
							countdownStartTime={countdownStartTime}
							style={{ marginTop: "24px" }}
						/>
					}
				</DrawerDialog>
			}
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DC38A.defaultProps = {
	showFull: false,
};

DC38A.propTypes = {
	epDevice: PropTypes.object.isRequired,
	deviceType: PropTypes.shape({
		clusterId: PropTypes.string.isRequired,
		cap: PropTypes.string.isRequired,
	}).isRequired,
	relativePathPrefix: PropTypes.string.isRequired,
	showFull: PropTypes.bool,
};

export default DC38A;
